<template>
  <div class="formuleEssentielle">
    <div class="vid">
      <video id="video" src="../assets/background6.mp4" autoplay="true" preload="auto" loop muted></video>
    </div>

    <h2><span class="orange">Formule</span> Essentielle</h2>

    <p class="about">Retrouvez ci dessous quelques modèles personnalisables de la formule économique :</p>

    <div class="conteneur">
      <div class="masonry-eco">
          <div class="item" v-for="template in templates" :key="template.id" :onmouseover=template.hoverColor onmouseout="this.style.backgroundColor='';" >
            <img :src="template.image" class="card-img-top" alt="...">
            <h5>{{template.name}}</h5>
            <div class="note">{{template.description}}</div>
            <a :href="template.lien" :class="template.classBouton" target="_blank">En savoir plus</a>
          </div>
        </div>
    </div>
    
  </div>
</template>

<style scoped>
.about{
  font-family: "jura";
  color: #fff;
  font-size: 22px;
  text-align: center;
  margin: 20px auto;
}

.note {
  white-space: pre-line;
}

.masonry-eco {
  margin: 5px auto 0 auto;
  max-width: 80%;
  column-gap: 1.5em;
  padding-bottom: 50px;
}

.item img{
  width: 100%;
}

.item h5{
  color: white;
  font-size: 25px;
  font-family: "jura";
}

.item a{
  width: 80%;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  background-color: transparent;
  border: 1px solid;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: 0.2s;
  font-family: "OPR";
}

.dark {
  color: #fff;
  border-color: #606264;
}

.dark:hover {
	color: #fff;
	background-color: #606264;
	border-color: #606264;
}

.primary {
	color: #007bff;
	border-color: #007bff;
}

.primary:hover {
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}

.warning {
	color: #ffc107;
	border-color: #ffc107;
}

.warning:hover {
	color: #212529;
	background-color: #ffc107;
	border-color: #ffc107;
}

.danger {
	color: #dc3545;
	border-color: #dc3545;
}

.danger:hover {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
}

/* The Masonry Brick */
.masonry-eco .item {
  padding: 1em;
  margin: 0 0 1.5em;
  border: solid rgb(180, 177, 177) 2px!important;
  transition : 0.5s;
  border-radius: 5px;
  text-align: center;
}

/* Masonry on medium-sized screens */
@media only screen and (min-width: 768px) {
  .masonry-eco {
    column-count: 2;
  }
}
</style>

<script>

export default {
  name: 'FormuleEssentielle',
  data(){
    return {
      templates : {
            "Restaurant à sushi": {
              "name": "Restaurant à sushi",
              "description": "",
              "image":require('../assets/temprestoeco.png'),
              "lien" :"/templates_eco/template_resto/",
              "hoverColor":"this.style.backgroundColor='rgba(100, 100, 100, 0.4)';",
              "classBouton" : "dark",
            },
            "Entreprise de BTP": {
              "name": "Entreprise de BTP",
              "description": "",
              "image":require('../assets/tempbtpeco.png'),
              "lien" :"/templates_eco/template_btp/",
              "hoverColor":"this.style.backgroundColor='rgba(255, 193, 7, 0.4)';",
              "classBouton" : "warning",
            },
            "Gite au bord de la mer": {
              "name": "Gîte au bord de la mer",
              "description": "",
              "image":require('../assets/tempgiteeco.png'),
              "lien" :"/templates_eco/template_gite/",
              "hoverColor":"this.style.backgroundColor='rgba(0, 95, 213, 0.4)';",
              "classBouton" : "primary",
            },
            "Aide à la personne": {
              "name": "Aide à la personne",
              "description": "",
              "image":require('../assets/tempaideeco.png'),
              "lien" :"/templates_eco/template_aide/",
              "hoverColor":"this.style.backgroundColor='rgba(255, 0, 0, 0.4)';",
              "classBouton" : "danger",
            },
          }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>